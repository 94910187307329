import type { MainPageBlock, MomentPlaylist } from '@package/sdk/src/api';
import { getFirstElement, isEmptyArray, UnexpectedComponentStateError } from '@package/sdk/src/core';

import { useMainPageApi } from '@/code/main-page/use-main-page-api';
import { useMomentPlaylistsApi } from '@/code/moments/use-moment-playlists-api';
import { MyChannelKinomCardDisplayedTitle } from '@/code/my-channel/my-channel';
import { NotificationLevel } from '@/code/notifications/notification';
import type { DropdownOption } from '@/components/app-dropdown/AppDropdown.vue';
import useLocale from '@/platform/localization/use-locale';
import { RouteQuery } from '@/platform/router/query';
import { useLayoutStore } from '@/stores/use-layout-store';

interface OpenMyChannelModalOptions {
  isRedirectFromPlaylistPage?: boolean;
  playlistId: string;
  momentId?: string;
}

export function useMyChannelModal() {
  const { fetchPlaylist } = useMomentPlaylistsApi();
  const { setCurrentModalName, addNotification } = useLayoutStore();
  const { fetchFilteredBlocks } = useMainPageApi();
  const { translate } = useLocale();

  const route = useRoute();

  const clearQuery = async () => {
    const query = { ...route.query };

    if (isEmptyArray(Object.keys(query))) {
      return;
    }

    Reflect.deleteProperty(query, RouteQuery.IsRedirectFromPlaylistPage);
    Reflect.deleteProperty(query, RouteQuery.MomentId);
    Reflect.deleteProperty(query, RouteQuery.PlaylistId);

    await navigateTo({ query }, { replace: true });
  };

  const openMyChannelModal = async ({
    playlistId,
    isRedirectFromPlaylistPage,
    momentId,
  }: OpenMyChannelModalOptions) => {
    let playlistOptions: DropdownOption[] = [];
    let fetchedPlaylist: MomentPlaylist;
    let fetchedPlaylists: MainPageBlock[];
    let selectedMomentId = momentId || '';

    try {
      [fetchedPlaylists, fetchedPlaylist] = await Promise.all([
        fetchFilteredBlocks({ type: 'moments' }),
        fetchPlaylist(playlistId),
      ]);

      playlistOptions = fetchedPlaylists.map<DropdownOption>((playlist) => ({
        value: playlist.playlistId,
        label: playlist.title,
      }));

      // возможна ситуация, когда на главной в плейлисте есть кином, но в плейлисте его не будет
      // если такое происходит, просто включаем первый кином
      const isMomentExist = fetchedPlaylist.moments.find((moment) => moment.id === momentId);
      if (!isMomentExist) {
        selectedMomentId = getFirstElement(fetchedPlaylist.moments)?.id || '';
      }
    } catch (error) {
      // ошибку показываем, только если пытаемся открыть невалидный плейлист с главной
      if (!isRedirectFromPlaylistPage) {
        addNotification({
          level: NotificationLevel.Error,
          message: translate('page.myChannel.playerOpenError'),
          hideTimeoutMs: 4000,
          position: 'top',
        });
      }

      throw new UnexpectedComponentStateError('playlist');
    }

    const isDefaultPlaylist = playlistOptions.find((option) => option.value === playlistId);

    // могут быть плейлисты, которых нет на главной
    // их добавляем в начало при открытии
    if (isRedirectFromPlaylistPage && !isDefaultPlaylist) {
      playlistOptions.unshift({ label: fetchedPlaylist.title, value: fetchedPlaylist.slug });
    }

    // т.к в данном случае эта модалка не будет открываться на странице контета,
    // то передаем сразу kinomCardDisplayedTitle: MyChannelKinomCardDisplayedTitle.ContentTitle
    setCurrentModalName('MyChannelModal', {
      options: {
        playlists: playlistOptions,
        moments: fetchedPlaylist.moments,
        fetchCallback: fetchPlaylist,
        selectedMomentId,
        selectedPlaylistId: playlistId,
        isLoadingFinished: true,
        isFullscreen: true,
        kinomCardDisplayedTitle: MyChannelKinomCardDisplayedTitle.ContentTitle,
      },
    });
  };

  const checkShouldMyChannelModalOpen = async () => {
    const momentId = route.query[RouteQuery.MomentId];
    const playlistId = route.query[RouteQuery.PlaylistId];
    const isRedirectFromPlaylistPage = Boolean(route.query[RouteQuery.IsRedirectFromPlaylistPage]);

    await clearQuery();

    // плейлист может быть без moment_id, поэтому проверяем просто на playlist_id
    const shouldKinomsModalOpen = Boolean(playlistId);

    if (shouldKinomsModalOpen) {
      await openMyChannelModal({
        momentId: String(momentId),
        playlistId: String(playlistId),
        isRedirectFromPlaylistPage,
      });
    }
  };

  return {
    checkShouldMyChannelModalOpen,
    openMyChannelModal,
  };
}
