<template>
  <article :class="$style.contentCard">
    <app-link
      :to="getContentRoute(content, { isPlayerPage: shouldRedirectToPlayerPage(content.accessKind) })"
      :class="$style.contentCardContainer"
      :aria-label="$t('ariaLabel.content.continueWatch', { title })"
      :data-tid="$getTestElementIdentifier($ElementTestIdentifierScope.Main, 'continueWatchItem')"
    >
      <div :class="$style.contentCardMedia">
        <app-img
          v-if="isContentUnavailable"
          :src="imageSrc"
          :alt="contentImageAlt"
          :width="$isMobile ? 350 : 400"
          :image-class="$style.contentCardImage"
        />

        <content-cover
          v-if="isContentUnavailable"
          :availability-message="availabilityMessage"
          :darkened="isContentUnavailable"
          :class="$style.contentCardCover"
          variant="episode"
        />

        <app-img
          v-if="!isContentUnavailable"
          :src="imageSrc"
          :alt="contentImageAlt"
          :width="$isMobile ? 350 : 400"
          :image-class="$style.contentCardImage"
        />

        <div :class="[$style.contentCardProgressWrapper, { [$style.hideBlock]: isContentUnavailableOrAvailableSoon }]">
          <div :style="{ width: toPercent(watchProgressPercentage) }" :class="$style.contentCardProgress" />
        </div>

        <p
          v-if="duration"
          :class="[$style.contentCardWatchProgress, { [$style.hideBlock]: isContentUnavailableOrAvailableSoon }]"
        >
          <span :class="$style.offset">{{ watchProgress.formattedOffset }}</span>
          /
          <span>{{ watchProgress.formattedDuration }}</span>
        </p>
      </div>

      <p v-if="title" :class="$style.contentCardTitle">
        {{ title }}
      </p>
    </app-link>

    <div>
      <icon-close
        :ref="(comp) => (buttonEl = comp?.$el)"
        size="medium"
        :class="$style.clear"
        :data-tid="$getTestElementIdentifier($ElementTestIdentifierScope.Common, 'removeButton')"
        @click="emit('clear-item')"
      />
      <notification-app-label v-if="buttonEl" :focus-el="buttonEl" label-class="label" label-position="right">
        {{ $t('content.watchingItems.remove') }}
      </notification-app-label>
    </div>
  </article>
</template>

<script lang="ts" setup>
import { useWatchingItemHelpers } from '@package/content-utils/src/code/use-watching-item-helpers';
import * as playerHelpers from '@package/media-player/src/player/helpers';
import { type Episode, type Media } from '@package/sdk/src/api';
import { MediaContentType } from '@package/sdk/src/api';
import { toPercent } from '@package/sdk/src/core';
import { computed, ref } from 'vue';

import useLDJSONMarkup from '@/code/linked-data-json-markup/use-ld-json-markup';
import IconClose from '@/components/icons/IconClose.vue';
import AppImg from '@/components/ui/AppImg.vue';
import AppLink from '@/components/ui/AppLink.vue';
import NotificationAppLabel from '@/components/ui/NotificationAppLabel.vue';
import useLocale from '@/platform/localization/use-locale';
import { useRouteUtils } from '@/platform/router/use-route-utils';

import ContentCover from './ContentCover.vue';

const props = defineProps<{
  content: Media;
}>();

const emit = defineEmits<{
  (e: 'clear-item'): void;
}>();

const { getContentRoute, shouldRedirectToPlayerPage } = useRouteUtils();
const { getContentTimeUnits } = useLDJSONMarkup();
const { translate } = useLocale();
const { getProgressPercentage } = useWatchingItemHelpers();

const { getAvailabilityMessage, showAvailabilityOverlay, isUnavailable, isAvailableSoon } =
  playerHelpers.useContentAvailability();

const buttonEl = ref<HTMLElement>();

const contentType = computed(() => props.content.contentType);
const duration = computed(() => props.content.duration);
const imageSrc = computed(() => props.content.preview || props.content.background);

const offset = computed(() => props.content.watchingItem?.offset as number);

const title = computed(() => {
  if (props.content.contentType === MediaContentType.MOVIE) {
    return props.content.title;
  }

  return (props.content as Episode).serialTitle as string;
});

const year = computed(() => props.content.year);

const contentImageAlt = computed(() => {
  const titleAndYear = `${title.value} ${year.value}`;

  return (contentType.value === 'episode' ? translate('common.serial') : translate('common.movie')) + titleAndYear;
});

const watchProgress = computed<{
  formattedOffset: string;
  formattedDuration: string;
}>(() => {
  const [offsetHours, offsetMinutes, offsetSeconds] = getContentTimeUnits(offset.value);
  const [durationHours, durationMinutes, durationSeconds] = getContentTimeUnits(duration.value);

  const formattedOffset =
    `${offsetHours}:` +
    (offsetMinutes < 10 ? `0${offsetMinutes}:` : `${offsetMinutes}:`) +
    (offsetSeconds < 10 ? `0${offsetSeconds}` : `${offsetSeconds}`);

  const formattedDuration =
    `${durationHours}:` +
    (durationMinutes < 10 ? `0${durationMinutes}:` : `${durationMinutes}:`) +
    (durationSeconds < 10 ? `0${durationSeconds}` : `${durationSeconds}`);

  return { formattedOffset, formattedDuration };
});

const watchProgressPercentage = computed(() => getProgressPercentage(offset.value, duration.value));

const isContentUnavailableOrAvailableSoon = computed(
  () => isUnavailable(props.content) || isAvailableSoon(props.content),
);

const isContentUnavailable = computed(() =>
  isContentUnavailableOrAvailableSoon.value ? showAvailabilityOverlay(props.content) : '',
);
const availabilityMessage = computed(() => getAvailabilityMessage(props.content));
</script>

<style lang="scss" module>
@use '@package/ui/src/styles/fonts' as fonts;
@use '@/assets/aspect-ratio' as aspect-ratio;
@use '@/assets/breakpoints' as breakpoints;

.contentCard {
  position: relative;
  display: flex;
  flex-direction: column;
  transition: transform ease-in-out 0.2s;
  transform: scale(1);

  &:hover {
    .contentCardTitle {
      color: var(--color-text-tertiary);
    }
  }
}

.contentCardContainer {
  position: relative;
  cursor: pointer;
}

.contentCardMedia {
  position: relative;
  display: block;
  width: initial !important;
  border-radius: var(--g-border-round-20);
  background-color: var(--color-bg-secondary);
  overflow: hidden;
  background-repeat: no-repeat;
  background-size: cover, 80px;
  background-position: top, center;
  object-position: center;

  @include aspect-ratio.ratio(1.5, 1);
}

.contentCardImage {
  position: absolute;
  top: 50%;
  left: 50%;
  width: auto;
  height: 100%;
  transform: translate(-50%, -50%) scale(1.1);
}

.contentCardCover {
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}

.contentCardProgressWrapper {
  position: absolute;
  bottom: 0;
  padding: 0 11px;
  width: 100%;
  height: 2px;
}

.contentCardProgress {
  height: 100%;
  background-color: var(--color-notheme-white-40);
}

.contentCardWatchProgress {
  position: absolute;
  bottom: 21px;
  left: 23px;
  color: var(--color-text-tertiary);

  @include fonts.WebLabel-3;
}

.contentCardTitle {
  display: inline-block;
  margin: var(--g-spacing-10) 0 0;
  width: fit-content;
  color: var(--color-text-primary);

  @include fonts.WebBody-1;
}

.offset {
  color: var(--color-text-primary);
}

.clear {
  position: absolute;
  top: 22px;
  right: 22px;
  z-index: var(--z-index-carousel-clear);
  cursor: pointer;
}

.hideBlock {
  display: none;
}

@include breakpoints.max-width-800 {
  .contentCardMedia {
    background-size: cover, 40px;
  }
}
</style>
