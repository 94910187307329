import {
  type ApiMainPage,
  type ApiMainPageFilteredBlocks,
  type MainPage,
  type MainPageBlock,
  MainPageMapper,
} from '@package/sdk/src/api';

import { ENDPOINTS } from '@/platform/http';

export interface MainPageFetchBlockOptions {
  page: number;
  perPage?: number;
}

interface MainPageFetchFilteredBlockOptions {
  type: 'moments';
}

export function useMainPageApi() {
  const { $http } = useNuxtApp();

  const fetchBlocks = (options?: MainPageFetchBlockOptions): Promise<MainPage> => {
    return $http
      .get<ApiMainPage>(ENDPOINTS.PAGES_MAIN, {
        query: {
          page: options?.page,
          ...(options?.perPage && {
            per_page: options?.perPage,
          }),
        },
      })
      .then(MainPageMapper.map);
  };

  const fetchFilteredBlocks = (options: MainPageFetchFilteredBlockOptions): Promise<MainPageBlock[]> => {
    return $http
      .get<ApiMainPageFilteredBlocks>(ENDPOINTS.PAGES_MAIN_BLOCKS, {
        query: {
          type: options.type,
        },
      })
      .then((result: ApiMainPageFilteredBlocks) => MainPageMapper.map(result.blocks));
  };

  return { fetchBlocks, fetchFilteredBlocks };
}
