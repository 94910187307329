<template>
  <div :class="$style.mainPage">
    <div :class="$style.mainPageContent">
      <article v-if="promoblock" :class="$style.mainBlock">
        <promo-kinoms-slider
          :items="promoblock?.contentMomentsList || []"
          :title="promoblock.title"
          :data-tid="$getTestElementIdentifier($ElementTestIdentifierScope.Main, 'promoBlock')"
          :style="{ order: 1 }"
          @on-slide-left="mainPageAnalytics.onPromoHeaderSliderSwipedLeft()"
          @on-player-ready="onPlayerReady"
        />
      </article>

      <article v-if="data?.continueWatchItems?.length" :class="$style.mainBlock">
        <continue-watch
          :key="data?.continueWatchItems?.length"
          :items="data?.continueWatchItems"
          :class="{
            [$style.continueWatch]: true,
            [$style.continueWatchAsFirstItem]: !promoblock,
          }"
          :style="{ order: 2 }"
          @on-slide-left="mainPageAnalytics.onContinueWatchItemBlockSwipedLeft()"
        />
      </article>

      <article
        v-for="(block, index) in filteredPageBlocks"
        :key="index"
        :class="$style.mainBlock"
        :style="{ order: block.position }"
      >
        <div
          v-if="block.displayType === DisplayType.ContentMomentList"
          :data-tid="$getTestElementIdentifier($ElementTestIdentifierScope.Main, 'playlist')"
          :class="$style.contentMomentList"
        >
          <h2 :class="$style.momentsListTitle">
            {{ block.title }}
          </h2>

          <kinoms-list
            :class="$style.kinomsList"
            :use-content-title="true"
            :moments="block?.contentMomentsList || []"
            :data-tid="$getTestElementIdentifier($ElementTestIdentifierScope.Content, 'kinomsList')"
            @click="onMomentClick($event, block.playlistId)"
          />
        </div>

        <recommendations-list
          v-if="block.displayType === DisplayType.DsmlRecommendations && recommendations?.length"
          :items="recommendations"
          :class="$style.recommendations"
          :title="$t('page.main.recommendationsTitle')"
          :data-tid="$getTestElementIdentifier($ElementTestIdentifierScope.Main, 'recommendations')"
          @on-slide-left="mainPageAnalytics.onDsmlRecommendationsBlockSwipedLeft()"
        />

        <div
          v-if="block.displayType === DisplayType.OffersList && block.offerId"
          :data-tid="$getTestElementIdentifier($ElementTestIdentifierScope.Main, 'promoBanner')"
          :class="$style.mainPromoBanner"
        >
          <promo-offer-card
            v-if="data?.promoOffer"
            :promo-offer="data?.promoOffer"
            :placement-route="AppRoute.Index"
            :class="$style.promoOffer"
          >
            <div :class="$style.promoOfferButtonsBlock">
              <app-button
                :text="DEFAULT_BTN_NAME"
                :class="$style.promoOfferButton"
                :data-tid="$getTestElementIdentifier($ElementTestIdentifierScope.Common, 'offerButton')"
                @click="onPromoOfferButtonClick(block.offerId)"
              ></app-button>
            </div>
          </promo-offer-card>
        </div>

        <belt-list
          v-if="block.displayType === DisplayType.GenresBelt"
          :items="block.beltItems"
          :title="block.title"
          :data-tid="$getTestElementIdentifier($ElementTestIdentifierScope.Main, 'belt')"
        />

        <template v-if="block.displayType === DisplayType.TvChannelsList">
          <tv-channels-list
            v-if="channels?.length"
            :channels="channels"
            :title="block.title"
            :data-tid="$getTestElementIdentifier($ElementTestIdentifierScope.Main, 'channelsList')"
          />
          <moments-list-skeleton v-else :class="$style.channels" />
        </template>
      </article>
    </div>

    <div v-if="isLoading" :class="$style.skeletonContainer">
      <header-slider-skeleton v-if="currentPage === 1" />
      <recommendations-list-skeleton v-if="currentPage === 1" />
      <moments-list-skeleton />
    </div>

    <virtual-pagination
      v-if="!isLoading || !filteredPageBlocks.length"
      :current-page="currentPage"
      :has-loader="true"
      :page-count="pageCount"
      :distance="80"
      @change-current-page="onChangeCurrentPage"
    />

    <scroll-top />
  </div>
</template>

<script lang="ts" setup>
import useLogger from '@package/logger/src/use-logger';
import type { VijuPlayer } from '@package/media-player/src/player/modules/instance/player';
import { useKinomAnalytics, useMainPageAnalytics } from '@package/sdk/src/analytics';
import type { Channel, MainPage, MainPageBlock, Media, Moment } from '@package/sdk/src/api';
import { DisplayType, FeatureToggle } from '@package/sdk/src/api';
import type { OfferV2 } from '@package/sdk/src/api/offers/offer-v2';
import { getFirstElement } from '@package/sdk/src/core';
import { isClient, watchOnce } from '@vueuse/core';
import { storeToRefs } from 'pinia';
import { computed, ref } from 'vue';

import useAuthActions from '@/code/auth/use-auth-actions';
import { useBreadcrumbs } from '@/code/breadcrumbs/use-breadcrumbs';
import { useChannelsApi } from '@/code/channels/use-channels-api';
import { useMainPageContent } from '@/code/content/use-main-page-content';
import getKmzaPageValue from '@/code/kmza/get-kmza-page-value';
import { AdriverAnalyticEvent, useKmzaDriverEvents } from '@/code/kmza/use-driver-kmza';
import useSafeScrollableBodyElement from '@/code/layout/use-safe-scrollable-body-element';
import type { CommonMoment } from '@/code/moments/moments';
import { useMyChannelModal } from '@/code/my-channel/use-my-channel-modal';
import { useOffersApi } from '@/code/offer/use-offers-api';
import { SeoMetaPage, usePageMeta } from '@/code/seo/use-page-meta';
import type { DropdownOption } from '@/components/app-dropdown/AppDropdown.vue';
import HeaderSliderSkeleton from '@/components/header-slider/HeaderSliderSkeleton.vue';
import KinomsList from '@/components/kinoms-list/KinomsList.vue';
import BeltList from '@/components/main-page/BeltList.vue';
import ContinueWatch from '@/components/main-page/ContinueWatch.vue';
import PromoKinomsSlider from '@/components/main-page/PromoKinomsSlider.vue';
import RecommendationsList from '@/components/main-page/RecommendationsList.vue';
import PromoOfferCard from '@/components/offers-page/PromoOfferCard.vue';
import ScrollTop from '@/components/shared/ScrollTop.vue';
import VirtualPagination from '@/components/shared/VirtualPagination.vue';
import MomentsListSkeleton from '@/components/skeleton/MomentsListSkeleton.vue';
import RecommendationsListSkeleton from '@/components/skeleton/RecommendationsListSkeleton.vue';
import TvChannelsList from '@/components/tv-page/TvChannelsList.vue';
import AppButton from '@/components/ui/AppButton.vue';
import useLocale from '@/platform/localization/use-locale';
import { AppRoute } from '@/platform/router/routes';
import { useFeaturesStore } from '@/stores/use-features-store';
import { useLayoutStore } from '@/stores/use-layout-store';
import { useSessionStore } from '@/stores/use-session-store';

definePageMeta({
  layout: 'default',
});

const logger = useLogger('pages/index.vue');
const { $analyticSender } = useNuxtApp();
const { fetchChannelsRecommendations } = useChannelsApi();
const { fetchBlocks, fetchRecommendations, fetchWatchingItems } = useMainPageContent();
const offersApi = useOffersApi();

const mainPageAnalytics = useMainPageAnalytics($analyticSender);
const kinomAnalytics = useKinomAnalytics($analyticSender);

const { navigateToPayment, navigateToAuth } = useAuthActions();
const { injectAdriverScript, removeExistingAdriverScript } = useKmzaDriverEvents();

const { checkShouldMyChannelModalOpen, openMyChannelModal } = useMyChannelModal();

const { setupSeoMetadata } = usePageMeta();

const { getStatus } = useFeaturesStore();
const { isAuth } = storeToRefs(useSessionStore());
const { setDefaultBreadcrumbs } = useBreadcrumbs();

const layoutStore = useLayoutStore();
const { currentModalName } = storeToRefs(layoutStore);

const { clearScrollPosition } = useLayoutStore();
const { scrollBodyToTop } = useSafeScrollableBodyElement();
const route = useRoute();
const { translate } = useLocale();

const PAGE_SIZE = 3;
const currentPage = ref(1);
const pageCount = ref(20);

const recommendations = ref<Media[]>();
const channels = ref<Channel[]>([]);

const player = ref<VijuPlayer>();

const filteredPageBlocks = computed<MainPageBlock[]>(() => {
  if (!data.value?.blocks) {
    return [];
  }

  return data.value.blocks.filter(
    (block: MainPageBlock) =>
      block.displayType !== DisplayType.MomentList && block.displayType !== DisplayType.PromoBlock,
  );
});

const promoblock = computed(() => {
  if (!data.value?.blocks) {
    return undefined;
  }

  return data.value.blocks.find((block) => block.displayType === DisplayType.PromoBlock);
});

const onPromoOfferButtonClick = (offerId: string) => {
  injectAdriverScript(AdriverAnalyticEvent.PromoTryButton);

  return isAuth.value ? navigateToPayment(offerId) : navigateToAuth({ offerId });
};

const setupMetadata = (result: MainPage) => {
  const firstContentRow = result[0]?.contentMomentsList;

  if (!firstContentRow) {
    return;
  }

  const firstMoment = getFirstElement(firstContentRow) as Moment;

  setupSeoMetadata({ page: SeoMetaPage.Home, args: [firstMoment] });
};

const fetchChannels = () => {
  if (!getStatus({ name: FeatureToggle.TvChannelOnMain })) {
    return Promise.resolve([]);
  }

  return fetchChannelsRecommendations();
};

const {
  pending: isLoading,
  data,
  refresh,
  clear,
  error: err,
} = await useLazyAsyncData(
  async () => {
    let blocks: MainPageBlock[] = [];
    let continueWatchItems: Media[] = [];

    if (isClient && data.value?.continueWatchItems) {
      continueWatchItems = data.value?.continueWatchItems;
    }

    const [blocksPromiseResult, continueWatchItemsPromiseResult] = await Promise.allSettled([
      fetchBlocks({ page: currentPage.value, perPage: PAGE_SIZE }),
      continueWatchItems.length > 0 ? Promise.resolve(continueWatchItems) : fetchWatchingItems(),
    ]);

    if (blocksPromiseResult.status === 'fulfilled') {
      blocks = blocksPromiseResult.value;
    }

    if (continueWatchItemsPromiseResult.status === 'fulfilled') {
      continueWatchItems = continueWatchItemsPromiseResult.value;
    }

    let promoOffer: OfferV2 | undefined;

    const promoOfferId = blocks.find((block) => block.displayType === DisplayType.OffersList)?.offerId;

    if (promoOfferId) {
      const fetchedOffer = await offersApi.getById(promoOfferId);

      if (fetchedOffer) {
        promoOffer = fetchedOffer;
      }
    }

    setupMetadata(blocks as MainPage);

    if (isClient && data.value?.blocks?.length && currentPage.value !== 1) {
      const items = [...(data.value?.blocks || []), ...blocks] as MainPageBlock[];

      if (blocks.length <= 2) {
        pageCount.value = Math.ceil(items.length / PAGE_SIZE);
      }

      return { blocks: items, promoOffer: promoOffer || data?.value?.promoOffer, continueWatchItems };
    }

    return {
      blocks,
      continueWatchItems,
      promoOffer,
    };
  },
  { server: true },
);

const DEFAULT_BTN_NAME = translate('offerPage.promoBanner.defaultButtonText');
const onChangeCurrentPage = async (newCurrentPage: number) => {
  currentPage.value = newCurrentPage;
  await refresh();
};

setDefaultBreadcrumbs();

if (err.value) {
  console.error(err.value);
}

const defaultPlaylists = computed(() => {
  return data.value?.blocks.filter((block) => block.displayType === DisplayType.ContentMomentList);
});

const defaultPlaylistOptions = computed<DropdownOption[]>(() => {
  if (!defaultPlaylists.value) {
    return [];
  }

  return defaultPlaylists.value.map((playlist) => ({
    label: playlist.title,
    value: playlist.playlistId,
  }));
});

const onPlayerReady = (plr: VijuPlayer) => {
  player.value = plr;
};

const onMomentClick = (moment: CommonMoment, playlistId: string) => {
  kinomAnalytics.onGotoKinomPage({
    page: getKmzaPageValue(route.name as AppRoute),
    moment,
  });

  openMyChannelModal({
    defaultPlaylistOptions: defaultPlaylistOptions.value,
    momentId: moment.id,
    playlistId,
  });
};

const doCheckShouldMyChannelModalOpen = async () => {
  try {
    await checkShouldMyChannelModalOpen();
  } catch (error) {
    logger.error(error);
  }
};

// при редиректе без ssr в маунтеде еще не подгружены плейлисты, поэтому делаем вотч
watchOnce(
  () => data.value,
  async () => {
    if (isAuth.value) {
      await doCheckShouldMyChannelModalOpen();
    }
  },
);

watch(currentModalName, (val, prevValue) => {
  if (prevValue === 'MyChannelModal' && !val && player.value) {
    player.value.play();
  }
});

onMounted(async () => {
  injectAdriverScript(AdriverAnalyticEvent.RecommendationsList);
  recommendations.value = await fetchRecommendations();

  channels.value = await fetchChannels();

  if (!isAuth.value) {
    await doCheckShouldMyChannelModalOpen();
  }
});

onBeforeUnmount(() => {
  clear();

  removeExistingAdriverScript(AdriverAnalyticEvent.RecommendationsList);
  clearScrollPosition(route.name);
  nextTick(scrollBodyToTop);
  layoutStore.clearScrollPosition(route.name);
});
</script>

<style lang="scss" module>
@use '@package/ui/src/styles/fonts.scss' as fonts;
@use '../assets/breakpoints' as breakpoints;

.mainPage {
  overflow: hidden;
  margin-bottom: var(--g-spacing-40);
}

.mainPageContent {
  display: flex;
  flex-direction: column;
}

.recommendations {
  padding: 0 var(--g-spacing-48) 0;
}

.contentMomentListCategory {
  color: var(--color-text-tertiary);
}

.channels {
  padding: 0 var(--g-spacing-48);
}

.contentMomentListTitle {
  position: relative;
  display: flex;
  align-items: center;
  margin-top: var(--g-spacing-6);
  width: fit-content;
  cursor: pointer;

  @include fonts.WebTitle-2;
}

.contentMomentListTitleName {
  margin-bottom: 0;

  @include fonts.WebTitle-2;
}

.contentMomentListPlayer {
  margin-top: var(--g-spacing-32);
}

.momentsListTitle {
  margin-bottom: var(--g-spacing-24);

  @include fonts.WebTitle-2;
}

.continueWatch,
.mainPromoBanner,
.contentMomentList {
  padding: 0 var(--g-spacing-48);
  overflow: hidden;
}

.mainBlock {
  display: flex;
  flex-direction: column;
}

.mainBlock:not(:last-child) {
  margin-bottom: 80px;
}

.mainPromoBanner {
  display: flex;
  align-items: center;
  justify-content: center;
}

.promoOfferButtonsBlock {
  display: flex;
  align-items: center;
  margin-top: var(--g-spacing-24);
}

.promoOfferButton {
  margin-right: var(--g-spacing-32);
  padding: var(--g-spacing-12) var(--g-spacing-40);
  border-radius: var(--g-border-round-12);
}

.promoOfferText {
  @include fonts.WebCaption-1;
}

.continueWatch {
  margin: 0;
}

.continueWatchAsFirstItem {
  margin: 0;
  margin-bottom: 80px;
}

.skeletonContainer {
  padding: 0 var(--g-spacing-32);
  margin-top: 80px;
}

.promoBlock {
  position: relative;
  padding: 0 var(--g-spacing-32);
  overflow: hidden;
}

@include breakpoints.max-width-800 {
  .mainBlock:not(:last-child) {
    margin-bottom: var(--g-spacing-40);
  }

  .recommendations {
    padding: 0 var(--g-spacing-20);
  }

  .momentsListTitle {
    max-width: 100%;
  }
  .continueWatch,
  .contentMomentList,
  .mainPromoBanner {
    padding: 0 var(--g-spacing-20);
  }

  .promoBlock {
    padding: 0 var(--g-spacing-16);
  }

  .continueWatch {
    margin-top: var(--g-spacing-40) 0 0 0;
  }

  .continueWatchAsFirstItem {
    margin: 0;
    margin-bottom: var(--g-spacing-40);
  }

  .skeletonContainer {
    padding: 0 var(--g-spacing-16);
  }
}

@include breakpoints.max-width-480 {
  .promoOfferButtonsBlock {
    flex-direction: column;
    width: 100%;
  }

  .promoOfferButton {
    margin: 0;
    margin-bottom: var(--g-spacing-16);
    width: 100%;
  }
}
</style>
