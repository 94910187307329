<template>
  <div :class="$style.belt">
    <span :class="$style.beltTitle">{{ title }}</span>

    <app-slider :breakpoints="sliderBreakpoints" :items="items">
      <template v-slot="{ item }">
        <app-link
          :style="`--active-background: ${item.backgroundColor}`"
          :to="getTagPath(item.genresSlugs)"
          :data-tid="$getTestElementIdentifier($ElementTestIdentifierScope.Main, 'beltItem')"
          :class="$style.beltListItem"
          @click="onItemClick"
        >
          <div :class="$style.beltListItemImageWrapper">
            <app-img
              :width="0"
              :with-src-set="false"
              :src="item.icon || ''"
              :lazy="true"
              belt-stub
              :image-class="$style.beltListItemImage"
              use-stub
            />
          </div>
          <span :class="$style.beltListItemName">{{ item.name }}</span>
        </app-link>
      </template>
    </app-slider>
  </div>
</template>

<script lang="ts" setup>
import { AnalyticEventNameNew, AnalyticPageName, useMainPageAnalytics } from '@package/sdk/src/analytics';
import type { GenresBeltItem } from '@package/sdk/src/api';
import { onMounted } from 'vue';
import type { RouteLocationRaw } from 'vue-router';

import { AdriverAnalyticEvent, useKmzaDriverEvents } from '@/code/kmza/use-driver-kmza';
import { AppRoute } from '@/platform/router/routes';
import { useCatalogStore } from '@/stores/use-catalog-store';

import type { SliderBreakpoints } from '../app-slider/AppSlider.vue';
import AppImg from '../ui/AppImg.vue';
import AppLink from '../ui/AppLink.vue';

withDefaults(
  defineProps<{
    items: GenresBeltItem[];
    title?: string;
  }>(),
  {
    title: '',
  },
);

const { $sendAnalyticEvent } = useNuxtApp();

const { $analyticSender } = useNuxtApp();
const mainPageAnalytics = useMainPageAnalytics($analyticSender);
const { injectAdriverScript } = useKmzaDriverEvents();

const catalogStore = useCatalogStore();

const sliderBreakpoints: SliderBreakpoints = {
  mobile: 2,
  smallTablet: 3,
  mediumTablet: 3,
  bigTablet: 5,
  smallDesktop: 5,
  mediumDesktop: 5,
  bigDesktop: 7,
  largeDesktop: 7,
};

const onItemClick = async () => {
  catalogStore.selectedSlugsWatcher.pause();
  $sendAnalyticEvent({ event: AnalyticEventNameNew.GotoCatalogPage, page: AnalyticPageName.Main });
  injectAdriverScript(AdriverAnalyticEvent.PickEmotionButton);

  await nextTick();
  catalogStore.selectedSlugsWatcher.resume();
};

const getTagPath = (slugs: string[]): RouteLocationRaw => ({
  name: AppRoute.MovieFilter,
  params: {
    f: `${slugs.join('-')}`,
  },
});

onMounted(() => {
  mainPageAnalytics.onGenresBeltListDisplayed();
});
</script>

<style lang="scss" module>
@use '@package/ui/src/styles/fonts.scss' as fonts;
@use '../../assets/breakpoints' as breakpoints;

.belt {
  display: flex;
  flex-direction: column;
  padding: 0 var(--g-spacing-48);
  width: 100%;
}

.beltTitle {
  margin-bottom: 30px;

  @include fonts.WebTitle-2;
}

.beltListItem {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: auto;
  height: 256px;
  border-radius: 24px;
  background-color: var(--active-background);
}

.beltListItemImageWrapper {
  align-self: center;
  width: 98px;
  height: 98px;
  margin-top: var(--g-spacing-56);
  margin-bottom: var(--g-spacing-12);
}

.beltListItemImage {
  width: 100%;
}

.beltListItemName {
  display: -webkit-box;
  padding: 0 var(--g-spacing-10);
  overflow: hidden;
  color: var(--color-notheme-text-accent);
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  text-align: center;

  @include fonts.WebSubtitle-1;
}

@include breakpoints.min-width-3840 {
  .beltListItem {
    height: 500px;
  }
}

@include breakpoints.min-width-2560 {
  .beltListItem {
    max-width: 100%;
    height: 400px;
    justify-content: center;
  }

  .beltListItemImageWrapper {
    width: 200px;
    height: 200px;
  }
}

@include breakpoints.max-width-800 {
  .belt {
    padding: 0 var(--g-spacing-20);
  }

  .beltListItem {
    height: 162px;
  }

  .beltListItemImageWrapper {
    width: 58px;
    height: 58px;
    margin-top: var(--g-spacing-32);
  }
}
</style>
